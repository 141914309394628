import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles.css";

const App = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [error, setError] = useState("");
  const [tourDates, setTourDates] = useState([]);
  const [venueID, setVenueID] = useState(null);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [audience, setAudience] = useState("");
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://calogero-api.s3.eu-west-3.amazonaws.com/tourdates.json"
        );
        const data = await response.json();
        const uniqueTourDates = Array.from(
          new Set(data.map((date) => date.CrowdGlowVenueID))
        ).map((venueId) =>
          data.find((date) => date.CrowdGlowVenueID === venueId)
        );

        setTourDates(uniqueTourDates);
      } catch (error) {
        console.error("Error fetching tour dates:", error);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount

  const handleLogin = async () => {
    try {
      const response = await axios.post("/login", {
        username,
        password,
      });

      setApiKey(response.data.apiKey);
      setError("");
    } catch (err) {
      setApiKey("");
      setError("Invalid username or password");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSuccess("");
    // You can handle the message data as needed (e.g., send it to a server, update state, etc.)
    // console.log("Title:", title);
    // console.log("Message:", message);
    // console.log("API Key:", apiKey);
    // console.log("Venue ID:", venueID);
    // console.log("Audience:", audience);

    let isError = false;
    if (audience && title.length > 0 && message.length > 0) {
      if (audience === "specificVenue" && !venueID) {
        setFormError("Please select a venue");
        isError = true;
      }
      if (!isError) {
        setTitle("");
        setMessage("");
        setAudience("");
        setVenueID("");
        setFormError("");
        setFormSuccess("Push Notification sent!");
        const response = await axios.post("/sendPush", {
          title,
          message,
          apiKey,
          venueID,
          audience,
        });
      }
    } else {
      setFormError("Please complete the form to send the push notification");
    }
  };

  const login = () => {
    return (
      <div className="login">
        <img src="https://images.squarespace-cdn.com/content/v1/613fcefb709d455b9e021aec/ff24886d-f5cc-48e8-938e-4e0d6e4a0f14/transparentlogo.png?format=1500w" />
        <h2>Push Portal - Login</h2>
        <div className="inputRow">
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="inputRow">
          <label>Password: </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <button onClick={handleLogin}>Login</button>
        </div>
        {apiKey && <p>Your API Key: {apiKey}</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    );
  };

  const pushUI = () => {
    return (
      <div className="push">
        <img src="https://images.squarespace-cdn.com/content/v1/613fcefb709d455b9e021aec/ff24886d-f5cc-48e8-938e-4e0d6e4a0f14/transparentlogo.png?format=1500w" />

        <h2>Compose Push Notification</h2>
        {/* <form
          onSubmit={handleSubmit}
          style={{ width: "300px", margin: "0 auto" }}
        > */}
        <div className="inputRow">
          <label>Send to:</label>
          <select
            value={audience}
            onChange={(e) => setAudience(e.target.value)}
          >
            <option value="">Select an option</option>
            <option value="allFans">All fans</option>
            <option value="attended">All fans already attended a show</option>
            <option value="specificVenue">
              All fans already attended a specific show
            </option>
            <option value="notAttendedYet">
              All fans who haven't been to a show yet
            </option>
          </select>
        </div>

        {audience == "specificVenue" && (
          <div className="inputRow">
            <label htmlFor="venueSelect">Select Venue:</label>
            <select
              id="venueSelect"
              onChange={(e) => setVenueID(e.target.value)}
              value={venueID || ""}
            >
              <option value="" disabled>
                Select a venue
              </option>
              {tourDates.map((date) => (
                <option
                  key={date.CrowdGlowVenueID}
                  value={date.CrowdGlowVenueID}
                >
                  {`${date.City} - ${date.VenueName}`}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="inputRow">
          <label>Title: </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={50}
          />
        </div>
        <div className="inputRow">
          <label>Message: </label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            maxLength={150}
          />
        </div>

        <button type="submit" class="sendMessage" onClick={handleSubmit}>
          Send Push Notification
        </button>

        {formError && <p style={{ color: "red" }}>{formError}</p>}
        {formSuccess && <p style={{ color: "green" }}>{formSuccess}</p>}
        {/* </form> */}
      </div>
    );
  };

  return (
    <>
      {apiKey && pushUI()}

      {!apiKey && login()}
    </>
  );
};

export default App;
